.floatingDrawer {
  position: absolute;
  right: 15px;
  bottom: 15px;
  height: 75px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #eee;
  box-shadow: 0 4px 12px -4px rgba(51, 51, 51, 0.2);
  transition: background 0.2s ease-out 0.1s, height 0.2s ease, width 0.2s ease,
    border-radius 0.2s ease, box-shadow 0.2s ease-out;
  z-index: 10;
  &:not(.open):hover {
    box-shadow: 0 4px 20px -4px rgba(#2a79d5, 0.7);
  }
  .toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    background-color: #ff059f;
    box-shadow: 0 1px 15px 0 rgba(239, 146, 251, 0.3);
    width: 60px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      fill: #fff;
      transition: 0.2s ease;
      transform-origin: center;
      &:before,
      &:after {
        transition: 0.2s ease;
        background: #fff;
      }
    }
    p {
      color: #fff;
      position: relative;
      top: 2px;
      font-size: 14px;
    }
  }

  &.open {
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: 1110px) {
      overflow: auto;
    }
    border-radius: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #f8f8f8, #f2f2f2);
    box-shadow: -6px 6px 130px -12px rgba(51, 51, 51, 0.4);
    transition: background 0.1s ease, height 0.2s ease, width 0.2s ease,
      border-radius 0.2s ease, box-shadow 0.2s ease;
    .toggle {
      background: transparent;
      background-image: none;
      box-shadow: none;
      svg {
        fill: #333333;
        transform: rotate(45deg);
        transition: 0.24s ease-out;
        &:before,
        &:after {
          background: #999;
        }
      }
      p {
        color: #333333;
      }
    }
    .floatingMenu {
      padding-bottom: 65px;
      transition: background 0.1s ease, height 0.2s ease, width 0.2s ease,
        border-radius 0.2s ease, box-shadow 0.2s ease;
    }
  }
}
