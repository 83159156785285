.wrapper {
  position: relative;
  height: 300px; // default height in case its not set in style
  aspect-ratio: 1/1;
}

.gradientBg {
  border-radius: 2000px;
  background: linear-gradient(
    330deg,
    rgb(41, 30, 49) 0%,
    rgb(99, 46, 100) 35%,
    rgb(240, 98, 198) 100%
  );
}

.inners {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.envelopeImg {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  transform: rotate(-5deg) translate(-6%, 1%) scale(1.02);
}

.horizontal .envelopeImg {
  transform: rotate(83deg) translate(-1%, 0%) scale(1.02);
}

.img {
  height: 100%;
}

.topBadge {
  position: absolute;
  top: 4%;
  left: 17%;
}

.horizontal .topBadge {
  position: absolute;
  top: 17%;
  left: 3%;
}

.bottomBadge {
  position: absolute;
  right: 12%;
  bottom: -2%;
}

.horizontal .bottomBadge {
  position: absolute;
  right: 3%;
  bottom: 16%;
}

.cardFront .img {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.cellphone {
  justify-content: flex-start;
}

.cellphone .img {
  height: 80%;
}

.horizontal .cardFront .img {
  height: calc(100% * 5 / 7);
}

.horizontal .cellphone + .cardFront .img {
  height: calc(80% * 5 / 7);
}

// With gradient BG

.gradientBg .inners.cellphone {
  justify-content: flex-start;
  margin-left: 7%;
}

.gradientBg .img {
  height: 70%;
}

.gradientBg.horizontal .cardFront .img {
  height: 50%;
}

.gradientBg.horizontal .cellphone .img {
  height: 40%;
}

.gradientBg .topBadge {
  position: absolute;
  top: 17%;
  left: 27%;
}

.gradientBg.horizontal .topBadge {
  position: absolute;
  top: 27%;
  left: 17%;
}

.gradientBg .bottomBadge {
  position: absolute;
  right: 23%;
  bottom: 12%;
}

.gradientBg.horizontal .bottomBadge {
  position: absolute;
  right: 13%;
  bottom: 22%;
}
