.digitalCardHistoryWrapper {
  display: flex;
  flex-direction: column;
}

.digitalCardHistoryRowWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  margin: 0.5rem 0;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
  @media (max-width: 800px) {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.dchPreviewAndDetailsWrapper {
  display: flex;
  flex-grow: 1;
  column-gap: 1rem;
  @media (max-width: 800px) {
    flex-direction: column;
    row-gap: 0.5rem;
  }
}

.dchFrontImage {
  aspect-ratio: 7/5;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 112px;
  @media (max-width: 800px) {
    max-height: 33dvh;
  }
}

.dchCreatedOn {
  display: flex;
  align-items: center;
}

.dchContact {
  display: flex;
  align-items: center;
}

.dchShareLink {
  align-self: center;
  @media (max-width: 800px) {
    width: 100%;
  }
}
