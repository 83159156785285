.dialogInnerWrapper {
  max-height: 75dvh;
  overflow: auto;
  scrollbar-width: thin;
}

.cardImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.cardImg {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 7/5;
  object-fit: contain;
}

.title {
  margin-bottom: 0.5rem;
}

.message {
  margin-bottom: 0.5rem;
}

.success {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.contactCard {
  padding: 1rem;
  border-radius: 1rem;
  background-color: #f4f5f6;
  margin-bottom: 1rem;
}

.contactCard div[class*='Avatar'] {
  border-radius: 16px;
}
