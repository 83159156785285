.cardPreview {
  display: flex;
  width: 100%;
  outline: 4px solid white;
  outline-offset: -4px;
  padding: 1rem;
  flex-direction: row;
  border-radius: 1rem;
  background: rgb(237, 238, 240);
  background: linear-gradient(
    90deg,
    rgba(237, 238, 240, 1) 0%,
    rgba(245, 246, 247, 1) 58%
  );
  box-shadow: 0 4px 10px 0 #bdbaba29;
  min-width: 0;
  overflow: hidden;
}

.cardPreviewInnerLeft {
  display: flex;
  flex: 1 1 0;
  min-width: 0;
  column-gap: 1rem;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow-x: hidden;
  max-width: 250px;
  width: 100%;
  flex-shrink: 1;
}

.cardPreviewUrl {
  box-shadow: 0 4px 10px 0 #bdbaba29;
  width: auto;
  height: 60px;
}

.cost {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendType > p {
  color: #6b7280;
}
