.backgroundContainer {
  background: linear-gradient(to right, #D575E8, #98C7FD);
  width: 100dvw;
  height: 100dvh;
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #F6F6F600, #F6F6F600, #F6F6F6, #F6F6F6, #F6F6F6, #F6F6F6,);
  min-height: 100dvh;
  padding: 16px;
  font-family: Arial, sans-serif;
}

.closeButton {
  background: white;
  border: none;
  font-size: 20px;
  font-weight: bold;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:hover {
  background: #f3f4f6;
}

.offerCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  text-align: center;
  justify-content: center;
}

.cardImageContainer img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 18rem;
  object-fit: contain;
}

.offerText {
  font-size: 14px;
  color: #374151;
  border: 1px solid #93c5fd;
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.loginForm input {
  width: 100%;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
}

.tryPremiumButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: rgb(40, 40, 40);
  padding: 10px;
  border: none;
  border-radius: .8rem;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.featuresSection {
  background: white;
  width: 100%;
  max-width: 400px;
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featuresSection ul {
  list-style: none;
  padding: 0;
  color: #374151;
}

.featuresSection li {
  padding: 6px 0;
}

.viewMore {
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-decoration: underline;
  margin-top: 16px;
  cursor: pointer;
}

.terms {
  font-size: 12px;
  color: #6b7280;
  margin-top: 8px;
}

.terms a {
  text-decoration: underline;
}