@use 'sass:math';

$seconds: 360;
$secondPctg: math.div(100, $seconds);
$secondFraction: math.div($secondPctg, 3);
$startDelay: $secondFraction * 2;

@function getFramePosWithDelay($keyframePos) {
  @return $startDelay + ($secondFraction * $keyframePos);
}

@mixin baseAnimation {
  0% {
    width: 50px;
    height: 4px;
  }
  #{$startDelay}% {
    width: 50px;
    height: 4px;
    top: 12px;
    background-color: #dedede;
  }
  #{getFramePosWithDelay(1)}% {
    width: 110px;
    height: 30px;
    top: 4px;
    background-color: #4d4d4d;
  }
  #{getFramePosWithDelay(1.6)}% {
    width: 90px;
    height: 20px;
  }
  #{getFramePosWithDelay(2.6)}% {
    width: 100px;
    height: 24px;
  }
  #{getFramePosWithDelay(9)}% {
    width: 100px;
    height: 24px;
    top: 4px;
    background-color: #4d4d4d;
  }
  #{getFramePosWithDelay(10)}% {
    width: 50px;
    height: 4px;
    top: 12px;
    background-color: #dedede;
  }
}

@keyframes handleAnimUp {
  @include baseAnimation;
}

@keyframes handleAnimDown {
  @include baseAnimation;
  0% {
    width: 50px;
  }
  #{$startDelay}% {
    width: 50px;
  }
  #{getFramePosWithDelay(1)}% {
    width: 130px;
  }
  #{getFramePosWithDelay(1.6)}% {
    width: 120px;
  }
  #{getFramePosWithDelay(2.6)}% {
    width: 130px;
  }
  #{getFramePosWithDelay(9)}% {
    width: 130px;
  }
  #{getFramePosWithDelay(10)}% {
    width: 50px;
  }
}

@mixin baseInsideAnimation {
  0% {
    transform: scale(0%);
    opacity: 0;
  }
  #{$startDelay}% {
    transform: scale(0%);
    opacity: 0;
  }
  #{getFramePosWithDelay(1)}% {
    transform: scale(100%);
  }
  #{getFramePosWithDelay(1.6)}% {
    transform: scale(80%);
    opacity: 1;
  }
  #{getFramePosWithDelay(2.6)}% {
    transform: scale(100%);
    opacity: 1;
  }
  #{getFramePosWithDelay(9)}% {
    transform: scale(100%);
    opacity: 1;
  }
  #{getFramePosWithDelay(10)}% {
    transform: scale(0%);
    opacity: 0;
  }
}

@keyframes animateInsideUp {
  @include baseInsideAnimation;
}

@keyframes animateInsideDown {
  @include baseInsideAnimation;
}

@keyframes opacityFlow {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 40%;
  }
}

@keyframes arrowMove {
  0% {
    top: 0%;
  }
  100% {
    top: 30%;
  }
}

.wrapper {
  width: 50px;
  height: 4px;
  background-color: #dedede;
  border-radius: 40px;
  display: flex;
  transition: all 0.2s ease 0s;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 12px;
}

.shouldAnimateUp {
  animation: handleAnimUp 360s infinite;
}

.shouldAnimateDown {
  animation: handleAnimDown 360s infinite;
}

.shouldAnimateUp .icon,
.shouldAnimateUp .title {
  animation: animateInsideUp 360s infinite;
}

.shouldAnimateDown .icon,
.shouldAnimateDown .title {
  animation: animateInsideDown 360s infinite;
}

.wrapper .icon,
.wrapper .title {
  transform: scale(0%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1) 0s;
}

.title {
  color: white;
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: 500;
  white-space: nowrap;
}

.arrows {
  position: relative;
  width: 24px;
  height: 24px;
}

.arrow {
  position: absolute;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 9px;
  animation: opacityFlow 0.8s infinite alternate ease;
}

.arrow svg {
  position: relative;
  animation: arrowMove 0.5s infinite alternate ease;
}

.arrows .arrow:first-child {
  top: 10%;
}

.arrows .arrow:nth-child(2) {
  top: 30%;
  animation-delay: 0.25s;
}
