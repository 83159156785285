@import '../../../chrome/styles/variables';

// Base Colors
$shade-10: $purple !default;
$shade-1: #dedede !default;
$shade-0: #fff !default;
$active: $green !default;

$range-width: 100% !default;

$range-handle-color: $shade-10 !default;
$range-handle-color-hover: $active !default;
$range-handle-size: 15px !default;

$range-track-color: $shade-1 !default;
$range-track-height: 10px !default;

$range-label-color: $shade-10 !default;
$range-label-width: 60px !default;

.increment,
.decrement {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  cursor: pointer;

  &:active {
    background-color: #e6e8eb;
  }
}

.increment {
  right: 0;
  top: -8px;
  font-size: 34px;
  line-height: 25px;
}

.decrement {
  left: 0px;
  top: -5px;
  font-size: 34px;
  line-height: 20px;
}

.rangeSlider {
  width: $range-width;
}

.scaleContainer {
  margin-top: 17px;

  h5 {
    text-align: center;
    margin-top: 12px;
    font-size: 15px;
    font-weight: 500;
    color: #6f8394;
  }
}

.scale {
  background: #fff;
  padding: 0 35px;
  position: relative;
  min-width: 150px;
  z-index: 105;
  height: 20px;
  display: inline-block;
  /* border-radius: 3px; */
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(202, 202, 202, 0.47),
    0 1px 2px rgba(202, 202, 202, 0.46);

  input[type='range'] {
    min-width: 150px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 4px;
    background: #e5e5e5;
    outline: none;
    padding: 0;
    margin: 0;
    left: 50%;
    position: relative;
    bottom: 3px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    @media (max-width: 586px) {
      width: 50%;
    }

    // Range Handle
    &::-webkit-slider-thumb {
      appearance: none;
      width: $range-handle-size;
      height: $range-handle-size;
      border-radius: 2px;
      background: linear-gradient(135deg,
          rgba(248, 78, 129, 0.89),
          rgba(204, 120, 225, 0.89));
      cursor: pointer;
      transition: background 0.15s ease-in-out;
    }

    &::-moz-range-thumb {
      appearance: none;
      width: $range-handle-size;
      height: $range-handle-size;
      border-radius: 2px;
      background: linear-gradient(135deg,
          rgba(248, 78, 129, 0.89),
          rgba(204, 120, 225, 0.89));
      cursor: pointer;
      transition: background 0.15s ease-in-out;
    }

    .increment {
      position: absolute;
      right: -25px;
      font-size: 23px;
      bottom: -10px;
    }

    .decrement {
      position: absolute;
      left: -25px;
      font-size: 34px;
      top: -14px;
    }
  }

  @media (max-width: 558px) {
    left: initial;
    transform: translateX(0);
  }

}


// Range Label
.range-slider__value {
  display: inline-block;
  position: relative;
  width: $range-label-width;
  color: $shade-0;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: $range-label-color;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid $range-label-color;
    border-bottom: 7px solid transparent;
    content: '';
  }
}

// Firefox Overrides
::-moz-range-track {
  background: $range-track-color;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}