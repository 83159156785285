@keyframes flash-move {
  0% {
    left: -10px;
  }
  90% {
    left: -10px;
  }
  100% {
    left: 150%;
  }
}

.wrapper {
  display: inline-flex;
  padding: 0 7px;
  margin: 6px;
  background-color: #3339;
  border-radius: 20px;
  column-gap: 6px;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.noBgColor {
  background-color: transparent;
}

.wrapper:hover .icon {
  transform: rotateY(180deg);
}
.icon {
  transition: all 0.4s ease 0s;
}

.flash {
  position: absolute;
  width: 4px;
  height: 30px;
  transform: rotateZ(30deg);
  z-index: 1;
  background-color: #fff;
  animation: 5s infinite normal flash-move;
  animation-timing-function: ease-in;
}

.miniFlash {
  width: 2px;
  height: 40px;
  transform: rotateZ(30deg) translateX(-5px);
}
