.addressForm {
  padding: 50px;
  background: #fff;
  overflow: hidden;
  &.editContact {
    padding: 0;
  }

  .header {
    border-top: 1px solid gray;
    padding: 1rem 0;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    h2 {
      margin-bottom: 0;
    }
  }

  .groupContainer {
    padding: 1rem 0;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    height: 60px;
  }

  .formContainer {
    width: 100%;

    .textField {
      div {
        input {
          padding: 0 0 8px 0;
          background: none;
        }

        div {
          padding-top: 0;
          padding-bottom: 3px;
          background: none;
        }
      }
    }
  }
  .contactField {
    display: flex;
    justify-content: space-between;
    > div {
      width: 100%;
      select {
        width: 100%;
      }
    }
    .nameField {
      width: 49%;
    }

    &.twoX {
      > div {
        width: 50%;
        margin: auto 0;
      }
    }
  }
  .dateFields {
    display: flex;
    flex-direction: column;
    .dateHeader {
      display: flex;
      margin: 10px 0 -9px 0;
      h6 {
        color: #9f9f9f;
      }
    }
  }
  .drawerHeader {
    h6 {
      margin-left: 10px;
      margin-bottom: 30px;
      font-weight: 500;
    }
    svg {
      path {
        fill: #f48fb1;
      }
    }
  }
  .contactLocation {
    display: flex;
    > div {
      width: calc(100% / 3);
    }
  }
  input[type='text'] {
    width: calc(100% - 7.5px);
    background: #f5f6f7;
    padding: 20px;
    box-shadow: none !important;
  }
  .flex {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 850px) {
  .addressForm {
    padding: 0;
  }
}
// The following width rule is pretty tricky as it applies to a large number of nested childs
@media (max-width: 550px) {
  .addressForm {
    .contactField {
      display: block;
      div {
        width: 100% !important;
      }
      div[class*='-Select'] + div {
        width: auto !important;
      }
    }
  }
  .addressModal {
    max-width: 100% !important;
    width: 100% !important;
    transform: none !important;
    // position: absolute !important;
    bottom: 0 !important;
    > div {
      > div {
        max-height: 100vh !important;
        overflow-y: auto !important;
      }
    }
  }
}
